import { useCallback, useEffect, useMemo, useState, Dispatch } from 'react'

import { Showcase, Offer, LinksResponse, LinksByOfferId, ShowcaseTypes } from '../types'

const DEBUG = window.location.port === '3001'
const HOST = DEBUG ? `${window.location.protocol}//${window.location.hostname}` : window.location.origin

export function useApp() {
  const showcase = useShowcase()
  const offers = useOffers(showcase?.id, showcase?.offer_count, showcase?.type)

  const offerIds = useMemo(() => offers.map(offer => offer.id), [offers])

  const linksByOfferId = useLinks(showcase?.user_id, showcase?.traffic_channel_id, offerIds, showcase?.click_parked_domain_id)

  return {
    showcase,
    offers,
    linksByOfferId,
  }
}

function useShowcase(): Showcase {
  const [showcase, setShowcase] = useState<Showcase>(window.__INITIAL_STATE__?.showcase)
  const load = useLoadShowcase(setShowcase)

  useEffect(() => {
    if (!showcase) {
      load()
    }
  }, [showcase, load])

  return showcase
}

function useLoadShowcase(setShowcase: Dispatch<Showcase>) {
  return useCallback(async (): Promise<void> => {
    const hostname = window.location.hostname
    const apiUrl = `${HOST}/api/showcases?hostname=${hostname}`

    const response = await fetch(apiUrl)
    const data = await response.json()
    const showcase = data?.showcase[0] as Showcase

    setShowcase({
      ...showcase,
      categories: showcase?.categories.map(Number),
      recommended_offers: showcase?.recommended_offers.map(Number),
      traffic_types: showcase?.traffic_types.map(Number),
    })
  }, [setShowcase])
}

function useOffers(showcaseId: string, offersCount: number, showcaseType: ShowcaseTypes): Offer[] {
  const [offers, setOffers] = useState<Offer[]>(window.__INITIAL_STATE__?.offers || [])
  const load = useLoadOffers(setOffers)

  useEffect(() => {
    if (!offers.length && showcaseId) {
      load(showcaseId, offersCount, showcaseType)
    }
  }, [offers, load, showcaseId, offersCount, showcaseType])

  return offers
}

function useLoadOffers(setOffers: Dispatch<Offer[]>) {
  return useCallback(async (showcaseId: string, offersCount: number, showcaseType: ShowcaseTypes): Promise<void> => {
    const apiUrl = `${HOST}/api/offers?showcase_id=${showcaseId}&perpage=${showcaseType === ShowcaseTypes.universal ? offersCount : '1000'}`

    const response = await fetch(apiUrl)
    const data = await response.json()
    const offers = (data?.offer as Offer[]) || []

    setOffers(offers)
  }, [setOffers])
}

function useLinks(userId: number, trafficChannelId: number, offerIds: string[], domainId?: number): LinksByOfferId {
  const [links, setLinks] = useState<LinksByOfferId>(window.__INITIAL_STATE__?.linksByOfferId || null)
  const load = useLoadLinks(setLinks)

  useEffect(() => {
    if (!links && userId && trafficChannelId && offerIds.length) {
      load(userId, trafficChannelId, offerIds, domainId)
    }
  }, [links, load, userId, trafficChannelId, offerIds, domainId])

  return links
}

function useLoadLinks(setLinks: Dispatch<LinksByOfferId>) {
  return useCallback(async (userId: number, trafficChannelId: number, offerIds: string[], parkedDomainId?: number): Promise<void> => {
    const domain = parkedDomainId ? `&parked_domain_id=${parkedDomainId}` : ''
    const apiUrl = `${HOST}/api/links?wm_ids[]=${userId}${domain}&${offerIds.map(id => `offer_ids[]=${id}`).join('&')}`

    const response = await fetch(apiUrl)
    const data: LinksResponse = await response.json()
    const allUserLinks = data?.[userId] ?? {}

    let links: LinksByOfferId = {}

    Object.keys(allUserLinks).forEach(offerId => {
      Object.keys(allUserLinks[offerId]).forEach(linkId => {
        if (allUserLinks[offerId][linkId].is_default) {
          const channelLinks = allUserLinks[offerId][linkId].links.filter(link => link.channel_id === trafficChannelId)

          if (channelLinks.length) {
            links[offerId] = channelLinks[0]
          }
        }
      })
    })

    setLinks(links)
  }, [setLinks])
}

type GADataLayer = { [key: string]: string }
declare global {
  interface Window {
    dataLayer: Array<GADataLayer>,
  }
}

export function useGtmPush() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, [])

  return useCallback((data: GADataLayer) => {
    window.dataLayer.push(data);
  }, [])
}