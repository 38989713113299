import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


const resources = {
  en: {
    translation: {
      amount: 'Amount',
      credit_limit: 'Credit Limit',
      cash_withdrawal: 'Cash Withdrawal',
      interest_rate: 'Interest Rate',
      annual_income: 'Annual Income',
      term: 'Term',
      maintenance: 'Maintenance',
      interest_free_period: 'Interest-Free Days',
      down_payment: 'Down Payment',
      legal_entity_name: 'Legal Entity Name',
      license_number: 'License Number',
      legal_address: 'Legal Address',
      max_annual_interest_rate: 'Maximum Annual Interest Rate',
      term_with_extension: 'Term with Extension',
      max_loan_term: 'Maximum Loan Term',
      cookieWarning: `By continuing to use our website, you consent to the processing of cookies and user data
(location information; OS type and version; browser type and version; device type and screen resolution;
the source from which the user came to the site; the website or advertisement; OS and browser language;
the pages opened and buttons clicked by the user; IP address) for site functionality,
retargeting, and statistical research. If you do not wish for your data
to be processed, please leave the site.`,
      cookieAcceptButton: 'Accept',
      creditorInfoHeader: 'Creditor Information',
      documentationSource: 'Detailed Information',
      bankButton: 'Apply',
      rangeFrom: 'from',
      rangeTo: 'to',
      days_one: 'day',
      days_other: 'days',
      weeks_one: 'week',
      weeks_other: 'weeks',
      months_one: 'month',
      months_other: 'months',
      years_one: 'year',
      years_other: 'years',
    }
  },
  ru: {
    translation: {
      amount: 'Сумма',
      credit_limit: 'Кредитный лимит',
      cash_withdrawal: 'Снятие наличных',
      interest_rate: 'Ставка',
      annual_income: 'Доход (годовых)',
      term: 'Срок',
      maintenance: 'Обслуживание',
      interest_free_period: 'Дней без %',
      down_payment: 'Первоначальный взнос',
      legal_entity_name: 'Наименование ЮЛ',
      license_number: '№ лицензии',
      legal_address: 'Юридический адрес',
      max_annual_interest_rate: 'Максимальная годовая процентная ставка',
      term_with_extension: 'Срок с продлением',
      max_loan_term: 'Максимальный срок займа',
      cookieWarning: `Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных
(сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана;
источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера;
какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта,
проведения ретаргетинга и проведения статистических исследований и обзоров. Если вы не хотите, чтобы ваши данные
обрабатывались, покиньте сайт.`,
      cookieAcceptButton: 'Принимаю',
      creditorInfoHeader: 'Информация о кредиторах',
      documentationSource: 'Подробная информация',
      bankButton: 'Оформить',
      rangeFrom: 'от',
      rangeTo: 'до',
      days_one: 'день',
      days_few: 'дня',
      days_many: 'дней',
      weeks_one: 'неделя',
      weeks_few: 'недели',
      weeks_many: 'недель',
      months_one: 'месяц',
      months_few: 'месяца',
      months_many: 'месяцев',
      years_one: 'год',
      years_few: 'года',
      years_many: 'лет',
    }
  },
  vi: {
    translation: {
      amount: 'Số Tiền',
      credit_limit: 'Hạn Mức Tín Dụng',
      cash_withdrawal: 'Rút Tiền Mặt',
      interest_rate: 'Lãi Suất',
      annual_income: 'Thu Nhập Hàng Năm',
      term: 'Thời Hạn',
      maintenance: 'Phí Dịch Vụ',
      interest_free_period: 'Số Ngày Miễn Lãi',
      down_payment: 'Khoản Trả Trước',
      legal_entity_name: 'Tên Pháp Nhân',
      license_number: 'Số Giấy Phép',
      legal_address: 'Địa Chỉ Pháp Lý',
      max_annual_interest_rate: 'Lãi Suất Tối Đa Hàng Năm',
      term_with_extension: 'Thời Hạn Có Gia Hạn',
      max_loan_term: 'Thời Hạn Vay Tối Đa',
      cookieWarning: `Bằng cách tiếp tục sử dụng trang web của chúng tôi, bạn đồng ý với việc xử lý cookie và dữ liệu người dùng
(thông tin vị trí; loại và phiên bản hệ điều hành; loại và phiên bản trình duyệt; loại thiết bị và độ phân giải màn hình;
nguồn gốc người dùng đến trang web; trang web hoặc quảng cáo nào; ngôn ngữ hệ điều hành và trình duyệt;
các trang đã mở và các nút nhấn của người dùng; địa chỉ IP) cho mục đích hoạt động của trang web,
tiếp thị lại và nghiên cứu thống kê. Nếu bạn không muốn dữ liệu của mình
được xử lý, vui lòng rời khỏi trang web.`,
      cookieAcceptButton: 'Chấp Nhận',
      creditorInfoHeader: 'Thông Tin Chủ Nợ',
      documentationSource: 'Thông Tin Chi Tiết',
      bankButton: 'Đăng Ký',
      rangeFrom: 'từ',
      rangeTo: 'đến',
      days: 'ngày',
      weeks: 'tuần',
      months: 'tháng',
      years: 'năm',
    }
  }
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['en', 'ru', 'vi'],
    detection: {
      order: ['querystring', 'navigator', 'localStorage'],
      lookupQuerystring: 'lng',
    },
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18next;