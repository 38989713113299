import React from 'react';
import Helmet from 'react-helmet'

import { useApp } from './hooks/app.hook';
import ShowcaseDispatcher from './ShowcaseDispatcher';

export default function App() {
  const { showcase, offers, linksByOfferId } = useApp();

  return (
    <>
      <Helmet>
        <title>{showcase?.title}</title>
      </Helmet>

      <ShowcaseDispatcher showcase={showcase} offers={offers} linksByOfferId={linksByOfferId} />
    </>
  );
}
