import React from 'react';

import Basic, { ShowcaseTemplateProps } from '../Basic';

import classes from './KiwiCream.module.scss'

export default function KiwiCream({ showcase, offers, linksByOfferId }: ShowcaseTemplateProps): JSX.Element {
  return (
    <Basic showcase={showcase} offers={offers} linksByOfferId={linksByOfferId} classes={classes} />
  );
}
