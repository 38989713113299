import React from 'react';
import queryString from 'query-string'

import { ShowcaseTemplateID, Showcase, Offer, LinksByOfferId } from './types';

import Basic, { ShowcaseTemplateComponent } from './templates/Basic';
import BlueberryCream from './templates/BlueberryCream';
import HoneyCream from './templates/HoneyCream';
import KiwiCream from './templates/KiwiCream';
import MoonlitNight from './templates/MoonlitNight';

type ShowcaseDispatcherProps = React.PropsWithChildren<{
  showcase: Showcase | null,
  offers: Offer[],
  linksByOfferId: LinksByOfferId,
}>

export default function ShowcaseDispatcher({ showcase, offers, linksByOfferId }: ShowcaseDispatcherProps): JSX.Element {
  let Template: ShowcaseTemplateComponent = Basic;

  const queryParams = queryString.parse(window.location.search)

  let templateId: number | null = Number(queryParams.template_id)

  if (!templateId || !ShowcaseTemplateID[templateId]) {
    templateId = showcase?.template_id ?? null
  }

  switch (templateId) {
    case ShowcaseTemplateID.HoneyCream:
      Template = HoneyCream;
      break;
    case ShowcaseTemplateID.MoonlitNight:
      Template = MoonlitNight;
      break;
    case ShowcaseTemplateID.BlueberryCream:
      Template = BlueberryCream;
      break;
    case ShowcaseTemplateID.KiwiCream:
      Template = KiwiCream;
      break;
    default:
      Template = Basic;
      break;
  }

  return (
    <Template showcase={showcase} offers={offers} linksByOfferId={linksByOfferId} />
  );
}
