export enum ShowcaseDisplayTypeID {
  Card = 1,
  Table = 2,
}

export enum ShowcaseTemplateID {
  HoneyCream = 1,
  MoonlitNight = 2,
  BlueberryCream = 3,
  KiwiCream = 4,
}

export type FileItem = {
  source: string,
  fileName: string,
  preview?: string,
}

export type SingleFile = [FileItem?]

export enum ShowcaseTypes {
  universal = 1,
  individual = 2,
}

export type Showcase = {
  id: string,
  name: string,
  user_id: number,

  is_active: boolean,
  domain_id: number,
  subdomain: string,
  click_parked_domain_id: number,

  geo_id: number,
  business_category_id: number,
  categories: number[],
  traffic_types: number[],
  traffic_channel_id: number,
  offer_count: number,
  recommended_offers: number[],
  recommended_offers_count: number,

  template_id: ShowcaseTemplateID,
  display_type_id: ShowcaseDisplayTypeID,

  favicon: SingleFile,
  logo: SingleFile,
  banner: SingleFile,
  title: string,
  advantages: string[],
  recommended_label: string,
  documentation: SingleFile,
  disclaimer: string,
  gtm_id: string,
  type: ShowcaseTypes,

  subaccount: string | null,
  subaccount2: string | null,
  subaccount3: string | null,
  subaccount4: string | null,
  subaccount5: string | null,
}

export type IconItem = {
  filename: string,
  path: string,
}

export type OfferCustomFields = {
  [key: string]: string | number | null,
}

export type Offer = {
  id: string,
  name: string,

  favicon: SingleFile,
  screen: SingleFile,
  icons: {
    favicon_svg?: IconItem,
    favicon_orig?: IconItem,
    favicon_16?: IconItem,
  },

  custom_fields: OfferCustomFields,
}

export type Link = {
  channel_id: number,
  url: string,
}

export type LinksResponse = {
  [userId: string]: {
    [offerId: string]: {
      [linkId: string]: {
        name: string,
        is_default: boolean,
        links: Link[]
      }
    }
  }
}

export type LinksByOfferId = {
  [offerId: string]: Link,
}
