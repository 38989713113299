import React from 'react';

import Basic, { ShowcaseTemplateProps } from '../Basic';

import classes from './MoonlitNight.module.scss'

export default function MoonlitNight({ showcase, offers, linksByOfferId }: ShowcaseTemplateProps): JSX.Element {
  return (
    <Basic showcase={showcase} offers={offers} linksByOfferId={linksByOfferId} classes={classes} />
  );
}
